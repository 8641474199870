.s-cart p {
  font-size: 10px;
  font-weight: 700;
}
.shopping-billing {
  padding: 0 0 25px 0;
}

.shopping-billing .form-check-input {
  background: #f9f9f9;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  color: #121212;
}

.shopping-billing .btn-primary {
  padding: 5px 10px !important;
  height: 54px;
  font-size: 12px;
}

.shopping-billing .btn-primary img {
  padding: 0 25px 0 0px;
}

.shopping-billing p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #605f5f;
}

.shopping-billing label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #605f5f;
  padding-left: 5px;
}

.shopping-billing .cards {
  margin: 7px 0;
  background: #f9f9f9;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 15px 12px;
}

.shopping-billing .cards img {
  display: inline-block;
  padding: 5px 6px;
}

.shopping-billing .form-check {
  margin-bottom: 6px;
}

.shopping-billing .address {
  background: #f9f9f9;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin: 0 0 25px;
}

.shopping-billing .address label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
  padding: 0 30px;
}

.shopping-billing .address p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #605f5f;
  margin-bottom: 0;
  padding: 0 30px;
}

.shopping-billing .address.bdrblck {
  border: 2px solid #121212;
}

.shopping-billing .promo-code {
  position: relative;
}

.shopping-billing .orders {
  background: #f9f9f9;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px 25px;
}

.shopping-billing .promo-code input,
.shopping-billing optgroup,
.shopping-billing select {
  padding: 18px 15px;
  margin: 0 0 18px;
  max-width: 420px;
}

.shopping-billing .promo-code .btn-secondary {
  float: right;
  margin: -71px 0px;
}

.shopping-billing .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  padding: 15px 15px;
}

.shopping-billing .cart-type {
  padding: 11px 15px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 18px;
  background: #fff;
}

.shopping-billing .cart-type p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
  margin-bottom: 0;
}

.shopping-billing .cart-type span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #605f5f;
}

.shopping-billing .cart-type .price {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 0 10px 0 45px;
  white-space: nowrap;
}

.shopping-billing .cart-type .cart-desc {
  padding: 0 25px;
}

.shopping-billing .cart-type img {
  background: #f3f3f3;
  border-radius: 12px;
  padding: 8px;
  padding: 13px 5px 5px;
}

.shopping-billing .cart-type i {
  color: #f46363;
  font-size: 22px;
  cursor: pointer;
}

/* .shopping-billing .cart-total {
    padding: 20px 0 0;
} */

/* .shopping-billing .cart-total p.heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
    text-align: center;
    color: #000000;
} */

/* .shopping-billing .cart-total p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #605F5F;
    margin: 0;
    padding: 27px 0;
} */

/* .shopping-billing .cart-total .total {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: right;
  color: #121212;
} */

.shopping-billing .btn-warning {
  margin: 30px 0;
  width: 100%;
}
.shopping-billing .help {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  color: #121212;
}
.form-check-input:checked[type="checkbox"] {
  background: url("../assets/images/check.png") no-repeat center center;
}
.cart-btm {
  max-width: 1160px;
  margin: 0 auto;
}

.cart-btm span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
}

.cart-btm p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #605f5f;
}

.cart-btm .border-right {
  border-right: #e6e8ec solid 2px;
}

.cart-btm .btn-warning {
  margin: 10px 0;
}

@media (max-width: 500px) {
  .cart-btm p {
    font-size: 12px !important;
  }
}

.address-form .col-sm-6,
.address-form .col {
  margin-bottom: 25px;
}
.mat-error {
  position: absolute;
}

.asteric {
  color: #ff0000;
}

.pad-0 {
  padding: 11px 5px !important;
}

.pad-0 option {
  font-size: 10px;
}
.mat-option {
  font-family: inherit;
}

.payment-logos {
  height: 31px;
  width: 127px;
  margin-top: 18px;
}

.order-details {
  font-size: small;
  line-height: 1.8;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 40px 30px 30px 30px;
  font-size: 18px;
  background-color: #f9f9f9;
}

.billing-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #6969693b;
  padding-top: 10px;
  line-height: 1.8;
}

.billing-row p {
  font-size: 16px;
  padding: 10px 0;
}

/* .price-card {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
}

.price-card * {
  font-size: 18px !important;
} */
