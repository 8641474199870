.zoom-effect {
  transition: transform 0.3s ease-in-out;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Zoom in effect on hover */
}

/* Productlist5.css */

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.cardy {
  animation: scaleIn 1.5s ease-in-out both;
}

.product-list {
  display: grid;
  justify-content: space-evenly;
  gap: 20px;
  align-self: center;
}

@media (min-width: 500px) {
  .product-list {
    grid-template-columns: repeat(2, 45%);
  }
}

@media (min-width: 990px) {
  .product-list {
    grid-template-columns: repeat(3, 30%);
  }
}

@media (min-width: 1300px) {
  .product-list {
    grid-template-columns: repeat(4, 24%);
  }
}
/* Productlist5.css */
@media only screen and (max-width: 768px) {
  img.product-list-image {
    display: none;
  }
  .cardyt{
    margin-top: 120px !important;
  }
}
