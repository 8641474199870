footer {
    border-top: 1px solid #dee2e6;
    background: #FCFCFD;
}

footer ul {
    list-style: none;
    padding: 0rem;
}

footer ul li a {
    font-size: 0.85rem;
    text-decoration: none;
    color: #474545;
    line-height: 2.5;
    font-weight: 500;
    transition: all .4s ease;
}

footer ul li a:hover {
    color: rgba(var(--brand-primary));
}

.footer-h4,
h4 {
    font-size: 0.9rem;
    font-weight: 300;
    padding: 1rem 0;
    line-height: 1.5;
}

.footer-h6,
h6 {
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 0.2rem;
    text-transform: capitalize;
}

.bold {
    font-weight: 700;
}

.copy {
    border-top: 1px solid #dee2e6;
    font-size: 0.72rem;
    color: #605F5F;
}

@media (max-width: 767px) {
    .copy {
        text-align: center;
    }
}

.copy .cookies {
    text-align: right;
}

@media (max-width: 767px) {
    .copy .cookies {
        text-align: center;
    }
}

.copy a {
    text-decoration: none;
    color: #f9d572 !important;
}

.social-media-links {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: -6px;
    margin-bottom: 20px;
}

.social-media-links img {
    object-fit: contain;
    transition: 0.1s ease;
}

.social-media-links img:hover {
    transform: scale(1.1);
}

.input-with-icon {
    display: flex;
    align-items: center;
  }
  
  .input-with-icon .form-control {
    flex-grow: 1;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .col-md-2 ul li a {
    color: initial;
    transition: color 0.3s;
  }
  
  .col-md-2 ul li a:hover {
    color: rgba(4, 69, 157, 1); /* Replace 1 with the desired alpha value */
  }