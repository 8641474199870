/* Contact Section Styling */
  
.contact h2 {
    font-weight: 800;
    margin-top: 15px;
}

.contact h5 {
    font-weight: 700;
    color: rgb(58, 58, 58);
}

.contact span {
    font-size: 10px;
}

.contact a {
    text-decoration: none !important;
    color: rgb(49, 49, 49);
}

.contact p {
    color: #727272;
    margin-bottom: 0px;
}

.contact .btn-secondary {
    padding: 7px 19px !important;
    font-size: 10px !important;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #858585 !important;
}

.contact .btn-secondary:hover {
    background: #f7c641 !important;
    border: 1px solid #f7c641 !important;
}

.contact .left .btn-primary {
    margin-bottom: 20px;
}

.contact .left label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: #121212;
    padding: 0 0px;
}

.contact .left .contact,
.contact .left .address {
    border: 1px solid rgba(160, 159, 159, 0.6);
    border-radius: 10px;
    padding: 20px;
}

.contact .left .contact h3,
.contact .left .address h3 {
    font-size: 18px;
    margin-top: 20px;
}

.contact .left .contact p,
.contact .left .address p {
    font-size: 14px;
    margin-top: 10px;
}

.contact .left .active {
    background: #F9D572 !important;
    border: 1px solid #F9D572 !important;
    color: #121212;
}

.contact .form-control {
    background-color: #fbfbfb;
}

.contact .right img {
    max-width: 400px;
}
.lightgray-lines {
    border: 1px solid lightgray;
    margin-top: -3px;
  }

  /* Add this to your CSS stylesheet */
.slanted-text {
    font-family: "Poppins", sans-serif; /* Use Poppins font */
    font-weight: 400; /* Extra Light 200 font weight */
    font-style: italic; /* Italic style */
  }

  #series {
    background: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
    color: white;
    transition: background-color 0.3s, color 0.3s;
    border: none; /* optional: remove button border */
  }
  
  #series:hover {
    background-color: rgb(47, 47, 255);
  }
  #topic{
    height: 45px;
    background-color: 1px solid gray;
  }