/* Define the width and height for the card */
.summary-card {
  width: 8cm;
  height: 9cm;
}

.cart-type {
  font-size: small;
  line-height: 1.8;
  border: 1px solid #6969693b;
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 25px;
  margin-right: 20px;
}

/* Ensure responsiveness by limiting the card's maximum width */
@media (max-width: 768px) {
  .summary-card {
    width: 100%; /* Allow the card to take the full width on smaller screens */
    height: auto; /* Allow the height to adjust accordingly */
    max-width: 8cm; /* Limit the maximum width to 8cm */
  }

  .cart-type {
    margin-right: 0;
    padding-right: 0;
  }
}

.card-text {
  font-weight: bolder;
}

.card-input {
  max-width: 60px;
  border: 1px solid #6969693b;
  border-radius: 7px;
  padding: 5px;
}

.cart-total {
  font-size: small;
  line-height: 1.8;
  border: 1px solid #6969693b;
  border-radius: 10px;
  padding: 40px 30px 30px 30px;
  /* margin-bottom: 25px; */
}

.total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #6969693b;
  padding-top: 5px;
  line-height: 1.8;
}

.qty-input:disabled {
  background: #dddcdc;
  color: #807f7f;
}

.flip-card {
  /* background-color: transparent; */
  width: 76px;
  height: 50px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  margin: 10px 0;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 5px;
}

.flip-card-back {
  transform: rotateY(180deg);
}
