nav {
    background: white;
    border-bottom: 1px solid #d4d0d0;
    padding: 20px 0;
}

nav a {
    text-decoration: none;
    color: #605F5F;
}
.login a {
    text-decoration: none;
}

.login .btn-grp button,
.login .btn-grp a {
    width: 140px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding: 6px 0;
}

.login .btn-grp img {
    margin-right: 10px;
}
.login-account {
    width: 310px;
    margin: 0 auto;
}

.login-right .btn-warning {
    padding: 10px 20px;
    background-color: #1568D4;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}

.login-right h1 {
    padding: 12px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #121212;
}

.login-right h2 {
    padding: 12px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #121212;
}

.login-right h3 {
    padding: 0 0 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #605F5F;
}

.login-right p {
    padding: 12px 0;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: #393F45;
}

.login-right .btn-primary {
    width: 100%;
}

.login-right .btn-primary img,
.login-right .btn-primary svg {
    padding: 3px;
    width: 24px;
}

.login-right .signup {
    padding: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #121212;
}

.login-right p.acc {
    padding: 35px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0;
    color: #959393;
}
.login-account {
    width: 310px;
    margin: 0 auto;
}

.login-right .btn-warning {
    padding: 10px 20px;
    background-color: #1568D4;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}

.login-right h1 {
    padding: 12px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #121212;
}

.login-right h2 {
    padding: 12px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #121212;
}

.login-right h3 {
    padding: 0 0 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #605F5F;
}

.login-right p {
    padding: 12px 0;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    color: #393F45;
}

.login-right .btn-primary {
    width: 100%;
}

.login-right .btn-primary img,
.login-right .btn-primary svg {
    padding: 3px;
    width: 24px;
}

.login-right .signup {
    padding: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #121212;
}

.login-right p.acc {
    padding: 35px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0;
    color: #959393;
}

.login-right .btn-primary .login-right p span {
    color: #959393;
}

.login-right .btn-warning img,
.login-right svg {
    padding: 7px 6px 7px 0px;
}
#myButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: white;
    border: 2px solid lightgrey;
    color: rgba(4, 69, 157, 1);
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
  }
  
  #myButton:hover {
    background-color: rgba(4, 69, 157, 1);
    color: white;
    border: 2px solid rgba(4, 69, 157, 1);
  }

  /* Style for the Facebook button */
#my-custom-button {
    background-color: rgba(4, 69, 157, 1);
    color: white;
    border: 2px solid rgba(4, 69, 157, 1);
    text-decoration: none;
    border-radius: 8px;
  }
  
  /* Apply hover styles for the Facebook button */
  #my-custom-button:hover {
    background-color: blue;
    border-color: blue;
  }
  /* Override the color for the link text */
.black {
    color: black !important;
  }
  
  /* Additional styles for the link */
  .black.fw-bold {
    font-weight: bold;
    /* Add more styles if needed */
  }
/* Override the color for the .grey class */
.grey {
    color: lightgrey !important;
  }
  
 /* Style for the login button */
#login-button {
    background: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
    color: white;
    border-color: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
    /* Additional styles as needed */
  }
  
  /* Apply hover styles for the login button */
  #login-button:hover {
    background-color: blue;
    border-color: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
    /* Additional hover styles as needed */
  }
/* Add this style to display error messages in red */
