.enterprise h5 {
    font-weight: 600;
}

.enterprise ul li {
    margin-bottom: 20px;
}

.enterprise ul li::before {
    font-size: 1.5rem;
}

.enterprise ul li b {
    font-weight: 600;
    font-size: 1.1rem;
}

.enterprise ul li p {
    color: #7c7c7c;
    text-transform: capitalize;
    padding-left: 10px;
}

.enterprise .f-height {
    max-height: 500px;
}

.enterprise .top {
    margin-top: -50px;
}

.info-text {
    text-align: center;
}

.info-text h3 {
    line-height: 1.8;
}
.df {
    background: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
    color: white; /* Text color */
    /* Add more styling as needed */
    text-decoration: none; /* Remove underline */
    padding: 10px 20px; /* Add padding as needed */
    border-radius: 9px; /* Add rounded corners */
}