.upgrade-plan {
    padding: 10px 0;
  }
  
  .upgrade-plan .analatic-img {
    max-width: 200px !important;
  }
  
  .upgrade-plan a {
    text-decoration: none;
  }
  
  .upgrade-plan .top-part span {
    font-size: 10px;
  }
  
  .upgrade-plan .con-pro h3 {
    margin-top: 30px;
  }
  
  .upgrade-plan .con-pro h3 b {
    font-weight: 700;
  }
  
  .upgrade-plan h6 {
    font-weight: 700;
    margin-top: 50px;
  }
  
  .upgrade-plan header h3,
  .upgrade-plan .contact-us header h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 56px;
    letter-spacing: -0.02em;
    color: #121212;
  }
  
  .upgrade-plan header h3 span,
  .upgrade-plan .contact-us header h3 span {
    font-weight: 700;
  }
  
  .upgrade-plan header .brd-crm,
  .upgrade-plan .contact-us header .brd-crm {
    padding: 35px 16px 0;
  }
  
  .upgrade-plan .upgrade-plan header .btn-primary img,
  .upgrade-plan .contact-us header .btn-primary img {
    padding: 0 25px 0 0px;
  }
  
  .upgrade-plan .basic-plan {
    padding: 25px 0;
  }
  
  .upgrade-plan .basic-plan p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #23262F;
    padding: 0 0 16px;
  }
  .basic-plan {
    margin-bottom: 30px;
  }
  
  .basic-plan-dsc {
    background: #FFFFFF;
    border: 2px solid rgba(116, 234, 207, 1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 25px 20px;
  }
  
  .basic-plan-dsc .la-check {
    color: rgba(116, 234, 207, 1);
    text-shadow: 2px 2px rgba(116, 234, 207, 1);
  }
  
  .basic-plan-dsc .la-times {
    opacity: 0.4;
    color: #7c7c7c;
    text-shadow: 2px 2px #000000;
  }
  
  .basic-plan-dsc .btn-primary {
    width: 100%;
  }
  
  .basic-plan-dsc p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #605F5F;
  }
  
  .basic-plan-dsc h2 {
    padding: 0 0 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #000000;
  }
  
  .basic-plan-dsc .price {
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
    color: #000000;
  }
  
  .basic-plan-dsc span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: normal;
    color: #605F5F;
  }
  
  .basic-plan-dsc ul {
    list-style: none;
    list-style-position: inside;
    margin: 0 0 15px 0;
    padding: 0;
  }
  
  .basic-plan-dsc ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
    line-height: 2;
    padding-left: 10px;
  }
  
  @media (max-width: 991px) {
    .basic-plan-dsc ul li {
      font-size: 10px;
    }
  }
  
  .basic-plan-dsc ul li .tick {
    margin-right: 10px;
  }
  .basic-plan .basic-plan-dsc {
    background: #FFFFFF;
    border: 2px solid rgba(116, 234, 207, 1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 25px 20px;
    margin-bottom: 30px;
  }
  
  .basic-plan .basic-plan-dsc .la-check {
    color: rgba(116, 234, 207, 1);
    text-shadow: 2px 2px rgba(116, 234, 207, 1);
  }
  
  .basic-plan .basic-plan-dsc .la-times {
    opacity: 0.4;
    color: #7c7c7c;
    text-shadow: 2px 2px #000000;
  }
  
  .basic-plan .basic-plan-dsc .btn-primary {
    width: 100%;
  }
  
  .basic-plan .basic-plan-dsc p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #605F5F;
  }
  
  .basic-plan .basic-plan-dsc h2 {
    padding: 0 0 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #000000;
  }
  
  .basic-plan .basic-plan-dsc .price {
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
    color: #000000;
  }
  
  .basic-plan .basic-plan-dsc span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: normal;
    color: #605F5F;
  }
  
  .basic-plan .basic-plan-dsc ul {
    list-style: none;
    list-style-position: inside;
    margin: 0 0 15px 0;
    padding: 0;
  }
  
  .basic-plan .basic-plan-dsc ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
    line-height: 2;
    padding-left: 10px;
  }
  
  @media (max-width: 991px) {
    .basic-plan .basic-plan-dsc ul li {
      font-size: 10px;
    }
  }
  
  .basic-plan .basic-plan-dsc ul li .tick {
    margin-right: 10px;
  }
  
  .btn-light-blue {
    background: rgba(116, 234, 207, 1);
    border: transparent;
  }
  
  .premium {
    padding: 30px 0;
  }
  
  .tick {
    width: 16px;
    height: 16px;
  }
  
  .pricing-table {
    justify-content: center;
  }
  
  @media (max-width: 1200px) {
    .pricing-table {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: flex-start;
    }
  }
  
  @media (max-width: 1024px) {
    .pricing-table .col-md-3 {
      width: 240px;
    }
  }
  .pro-main {
    margin-top: -20px;
  }
  
  .pro-main del {
    font-size: 20px;
  }
  
  .standard-btns {
    margin-top: 50px;
  }

  .card-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust the gap between cards */
    padding: 10px; /* Add padding around the card container */
    overflow: auto;
  }
  .card {
    flex-shrink: 0;
    padding: 20px; /* Add padding inside cards */
    max-width: 300px; /* Set the maximum width for the cards */
    box-sizing: border-box;
    justify-content: space-between;
  }
  
  .card-wrapper {
    flex-shrink: 0;
    width: auto;
    min-width: 50%;
    box-sizing: border-box;
    justify-content: flex-start;
  }
  @media (max-width: 992px) {
    .card-container {
      overflow-x: auto;
    }
  }
  .feature-list {
    list-style: none;
    padding-left: 0;
  }
  
  .feature-list li {
    display: flex;
    align-items: center; /* Align icon and text vertically */
    margin-bottom: 10px;
  }
  
  .feature-list li i {
    margin-right: 10px; /* Add spacing between icon and text */
    color: lightgray;
  }
  .choose-plan {
    align-self: center;
  }
  
  .choose-plan-button {
    display: inline-block;
    /* margin-top: 163px; */
    padding: 8px 10px; /* Adjust padding to make the button narrower */
    border: none;
    background-color: white;
    color: #04459d;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    width: 190px; /* Set a specific width */
    /* margin-left: 21px; */
  }
  .choose-plan-button2 {
    display: inline-block;
    /* margin-top: 6px; */
    padding: 8px 10px; /* Adjust padding to make the button narrower */
    border: 0px solid #04459d;
    background-color: #04459d;
    color: white;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    width: 190px; /* Set a specific width */
    /* margin-left: 30px; */
  }
  /* Media query for smaller screens */
@media (max-width: 768px) {
  .card {
    height: 465px !important;
  }
  .feature-list li {
    font-size: 13px !important;
    margin-bottom: 2px;
  }
  .choose-plan-button {
    margin-top: 116px;
    width: 160px;
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .card {
    height: 465px !important;
  }
  .feature-list li {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .card-container {
    justify-content: flex-start;
    /* background: #ffffff;
    border: 2px solid #74eacf;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 25px 20px;
    margin-bottom: 30px; */
  }

  .choose-plan-button2 {
    margin-top: 29px;
    width: 160px;
  }
  .choose-plan-button {
    margin-top: 143px;
    width: 160px;
    left: 20px !important;
  }
}
.no-bullets {
  list-style: none; /* This removes the default bullet points */
}