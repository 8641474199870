/* Header */
/* Navbar.css */
/* CSS for hiding the "Sign Out" and "Forgot Password" links */
.user-info {
  position: relative;
}

.login-link {
  color: white;
}

/* .user-options {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 9999;
} */

.user-info .user-options {
  position: absolute;
  top: -30%;
  left: 110%;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
  display: none;
  font-size: 12px;
}

.user-info:hover .user-options {
  display: block;
}

/* #upgrade-link::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(4, 69, 157, 1);
  visibility: hidden;
  transition: visibility 0s, width 0.3s;
} */

/* #upgrade-link:hover::before {
  visibility: visible;
  width: 100%;
} */

#login {
  background: linear-gradient(90deg, #04459d 1.87%, #3093ff 100%);
  color: white;
  border-radius: 7px;
  width: 2.1cm;
  height: 1.1cm;
  text-align: center;
  line-height: 1.1cm;
}

.nav.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 900;
  background-color: white; /* You can customize the background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional box shadow */
  height: 93px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.nav .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-img {
  max-width: 150px; /* Adjust the size as needed */
  height: 30px;
  margin-top: -8px;
}

/* Right Navigation */
.right-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* @media (max-width: 767px) {
  .right-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
  }
} */

/* .right-nav li:not(:last-child) {
  padding-right: 30px;
  margin-right: 10px;
} */

/* .right-nav img {
  width: 25px;
  height: 25px;
} */

/* User Info */
/* .right-nav .user-info img {
  width: 36px;
  height: 36px;
} */

/* .right-nav .user-info:hover .dropdown-menu {
  display: block;
  left: auto;
  right: 0;
} */

/* .right-nav .user-info .dropdown-menu .dropdown-item {
  font-size: 12px;
} */

/* .right-nav .user-info .dropdown-menu .dropdown-item:hover {
  background: #fdfdfd;
  border-radius: 8px;
} */

/* Cart Button */
/* .right-nav .cart-btn {
  position: relative;
} */

/* .right-nav .cart-btn .circle {
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0;
  width: 15px;
  height: 15px;
  padding: 5px;
  color: black;
  font-size: 10px;
  line-height: 2;
} */

/* .nav {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #dee2e6;
  position: relative;
} */

/* @media (max-width: 767px) {
  .navbar-nav {
    background-color: #f7f9fc;
  }
} */

.navbar-main-nav,
.right-nav,
.left-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.left-nav {
  gap: 20px;
}

.navbar-main-nav,
.right-nav {
  justify-self: start;
  gap: 10px;
}

.navbar-main-nav .item {
  .navbar-link {
    color: rgba(96, 95, 95, 1) !important;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.5rem 0.7rem;

    &:hover {
      color: rgba(4, 69, 157, 1) !important;
    }
  }
}

.navbar-main-nav .item,
.right-nav .item {
  list-style-type: none;
  .navbar-link {
    border-bottom: 3px solid #fff;
    font-size: 0.875rem;
    transition: all 0.4s ease;

    &:hover {
      border-bottom: 3px solid rgba(4, 69, 157, 1);
    }

    &.active {
      border-bottom: 3px solid rgba(4, 69, 157, 1);
      color: #fadb86 !important;
    }
  }
}

.item.ham-item {
  margin-top: 8px;
  padding-right: 10px;
  display: none;
  visibility: hidden;
}

@media (max-width: 992px) {
  .navbar-main-nav,
  .nav.fixed .item.user,
  .nav.fixed .item.login {
    display: none;
    visibility: hidden;
  }

  .item.ham-item {
    display: block;
    visibility: visible;
  }
}

/* .navbar-nav .dropdown .dropdown-menu {
  border: 1px solid #eee !important;
  padding: 0;
  max-width: 1160px;
  margin: 0 auto;

  @media (max-width: 767px) {
    box-shadow: none !important;
    border: 0 !important;
  }

  .dropdown .mega-content .container {
    width: 100%;
  }

  .dropdown .mega-content .list-group-item {
    font-weight: 600;
    font-size: 12px;
    line-height: 3;
    color: #605f5f !important;
    border: none;
    padding: 5px 25px 0px;

    @media (max-width: 767px) {
      padding-left: 5px;
    }

    &:hover {
      color: rgba(var(--brand-primary));
    }
  }

  .dropdown .mega-content h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #121212;
  }

  .dropdown .mega-content h5 {
    padding-left: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #121212;

    @media (max-width: 767px) {
      padding-left: 5px;
    }
  }

  .dropdown .mega-content span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #f9d572;
  }
} */

/* .navbar-nav .dropdown .dropdown-toggle::after {
  display: none;
} */

.navbar-nav .dropdown .nav-link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: rgba(var(--brand-primary)) !important;
}

.navbar-brand {
  padding-right: 0.9rem;
  padding-top: 0;

  img,
  svg {
    vertical-align: middle;
    width: 150px;
    height: 47px;
  }
}

.badge {
  background-color: #f9d572;
}

.lgnbtn {
  background-color: rgba(var(--brand-primary));
  border: 0;
  border-radius: 8px;
  color: #fff;
  padding: 12px 16px;
  font-size: 15px;
}

.lgnbtn.btn:hover {
  color: #fff;
}

/* 
.dr-2:hover .dropdown-menu,
.dr-2 .dropdown-menu.show {
  display: block !important;
  width: 87vw;
  position: absolute;
  left: -200px;
} */

/* @media (max-width: 990px) {
  .dr-2:hover .dropdown-menu {
    position: absolute;
    left: -15px;
  }
} */

/* .navbar-toggler {
  border: 0;
  outline: none;
  box-shadow: none;
} */

.navbar-toggler .icon-bar {
  background: #04459d;
  display: block;
  width: 19px;
  height: 3px;
  margin: 3px auto;
  border-radius: 10px;
}

.navbar-toggler[aria-expanded="true"] .icon-bar:nth-of-type(1) {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler[aria-expanded="true"] .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler[aria-expanded="true"] .icon-bar:nth-of-type(3) {
  transform: rotate(-45deg);
  transform-origin: -5% 90%;
}
.navbar-toggler[aria-expanded="false"] .icon-bar:nth-of-type(1) {
  transform: rotate(0);
}

.navbar-toggler[aria-expanded="false"] .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler[aria-expanded="false"] .icon-bar:nth-of-type(3) {
  transform: rotate(0);
}

/* .search-box {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 700px;
  margin: 10px auto;
}

.search-box form {
  position: relative;
}

.search-box form button {
  border: none;
  font-size: 24px;
  position: absolute;
  top: 6px;
  right: 7px;
  background: #000;
  color: #fff;
  transition: all 0.4s ease;
  border-radius: var(--border-radius);
  line-height: 1;
  padding: 5px;

  &:hover {
    background: rgb(var(--brand-primary));
  }
} */

.badge {
  text-align: center;
  border-radius: 50% !important;
  background-color: #f9d572;
  position: relative;
  top: -10px;
  right: 8px;
  color: #000 !important;
  font-size: 10px;
}

/* .badge-small {
  text-align: center;
  border-radius: 50% !important;
  background-color: #f9d572;
  position: absolute;
  top: -3px;
  right: 3px;
  color: #000 !important;
  font-size: 10px;
  padding: 1px 5px;
  font-weight: 500;
} */

.user {
  display: flex;
  align-items: center;
}

.auth-user {
  font-weight: bold;
  color: #04459d;
  padding: 2px 8px;
}

/* .list-arrow * {
  stroke: black;
  stroke-width: 30;
  font-size: 15px;
} */

/* .list-arrow {
  background-color: #fff;
  border-radius: 50%;
  padding: 3px 6px 4px 4px;

  z-index: 10;
  transition: all 0.05s ease-in;
} */

/* .list-arrow.active {
  transform: translateX(22px);
  box-shadow: 6px 1px 4px #00000025;
}

.list-group.card-select {
  background-color: whitesmoke;
}

.list-group.card-select * {
  background-color: whitesmoke;
}

.list-group {
  height: 100%;
  border-right: 1px solid #d0d0d088;
}

.list-group-item {
  color: #616161 !important;
} */

.discover-dropdown,
.nav-dropdown {
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  /* height: 200px; */
  z-index: 150;
  transition: all 0.4s ease-in-out;
  visibility: hidden;
}

.discover-dropdown {
  margin-top: -180px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.nav-dropdown {
  margin-top: -100vh;
  height: 100dvh;
  overflow-y: scroll;
}

.nav-dropdown.show {
  margin-top: 0;
  padding-top: 93px;
  visibility: visible;
}

.discover-dropdown .container {
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.container .left-container {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.discover-dropdown .container img,
.nav-dropdown .container img {
  width: 100%;
  height: 100%;
}

.discover-dropdown.show {
  margin-top: 93px;
  visibility: visible;
}

.nav-dropdown .large-cards {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 0.2px 1fr;
  gap: 15px;
  width: 100%;
  max-width: 550px;
  justify-content: center;
  gap: 5%;
}

.nav-dropdown .small-cards {
  margin-top: 20px;
  padding-top: 25px;
  border-top: 1px solid rgb(187, 185, 185);
  width: 100%;
  max-width: 550px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  row-gap: 10px;
}

.nav-dropdown .small-cards a {
  width: 100%;
  padding: 0.2em;
  max-width: 100px;
  justify-self: start;
}

.line {
  width: 0px;
  border-left: 1px solid rgb(187, 185, 185);
}

.nav-dropdown .small-cards a:nth-child(3n) {
  justify-self: end;
}

.nav-dropdown .small-cards a:nth-child(3n - 1) {
  justify-self: center;
}

.nav-dropdown .container {
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
}

.nav-dropdown .user {
  margin-top: auto;
  align-self: center;
}

/* .nav-dropdown .user .user-info .user-options {
  top: 0%;
  left: 0;
} */
