.ambassador h1 {
    font-weight: 500;
    margin-bottom: 20px;
}

.ambassador h1 span {
    font-weight: 799;
}

.ambassador h6 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.ambassador li {
    line-height: 1rem;
    margin-bottom: 10px;
}

.ambassador .top {
    margin-top: 40px;
}

.ambassador .f-height {
    max-height: 500px;
    margin-top: -100px;
}

@media (max-width: 767px) {
    .ambassador .f-height {
        margin-top: 0px;
    }

    .top {
        margin-top: 0px !important;
    }
}

.info-text {
    text-align: center;
}

.info-text h3 {
    line-height: 1.8;
}
.circle-bullet {
    list-style: none;
    padding-left: 0;
  }
  
  .circle-bullet li {
    display: flex; /* Display list items inline */
    align-items: center; /* Center align icon and text vertically */
    margin-bottom: 10px; /* Add some space between list items */
  }
  
  .circle-bullet li svg {
    margin-right: 10px; /* Add space between icon and text */
  }
  