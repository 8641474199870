.faq .top-part span {
    font-size: 10px;
}

.fw-medium {
    font-weight: 300;
    margin-top: 70px;
}

.fw-medium span {
    font-weight: 500;
}

a {
    text-decoration: none !important;
}
.faqs {
    padding: 0 0 37px;
}

.faqs header {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 56px;
    letter-spacing: -0.02em;
    color: #121212;
}

.faqs header span {
    font-weight: 700;
}

.faqs p,
.contact-us p {
    font-weight: 500;
    font-size: 15px;
}
.faq-content p,
.faq-content li {
    font-size: 0.85rem;
    color: #858383;
}

.faq-content .nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #959393;
    padding: 0px 61px 30px 0;
    border: transparent;
    border-bottom: 1px solid #dee2e6;
}

.faq-content .accordion-button:not(.collapsed) {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #121212;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
    background: #fff;
}

.faq-content .accordion-item {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.faq-content .accordion-item:last-child {
    border: none !important;
}

.faq-content .accordion-body {
    padding: 30px 0 30px;
}

.faq-content .accordion-button {
    padding: 36px 0;
}

.faq-content .accordion-button::after {
    background-size: .75rem;
}

.faq-content .sidebar .contact,
.faq-content .sidebar .address,
.faq-content .sidebar .left .contact,
.faq-content .sidebar .left .address {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 22px 30px;
}

.faq-content .sidebar .address img,
.faq-content .sidebar .address svg,
.faq-content .sidebar .address .sidebar .contact img,
.faq-content .sidebar .address .sidebar .contact svg,
.faq-content .sidebar .address .sidebar .left .address img,
.faq-content .sidebar .address .sidebar .left .address svg,
.faq-content .sidebar .address .sidebar .left .contact img,
.faq-content .sidebar .address .sidebar .left .contact svg {
    padding: 0 0 8px;
    width: 28px;
    height: 28px;
}

.faq-content .contact h3,
.faq-content .sidebar .address h3,
.faq-content .sidebar .left .contact h3,
.faq-content .sidebar .left .address h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    color: #121212;
}

.faq-content .contact span,
.faq-content .sidebar .address span,
.faq-content .sidebar .address p,
.faq-content .sidebar .left .contact span,
.faq-content .sidebar .left .address span,
.faq-content .sidebar .left .address p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #121212;
}

.faq-content .address p a {
    font-weight: 700;
    text-decoration: underline;
}
.nav-tabs .nav-link.active {
    background-color: #fff;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #121212;
}
.lightgray-line {
    border: 1px solid lightgray;
    margin-top: -3px;
  }