.modal-style {
  width: 95vw;
  max-width: 1040px;
}

.modal-header {
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.22),
    0px 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.column-1 {
  /* box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.12),
    2px 0px 2px 0px rgba(0, 0, 0, 0.16); */
  box-shadow: 4px 0px 3px 0px rgba(0, 0, 0, 0.22),
    2px 0px 2px 0px rgba(0, 0, 0, 0.16);
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.column-3 {
  box-shadow: -4px 0px 3px 0px rgba(0, 0, 0, 0.22),
    -2px 0px 2px 0px rgba(0, 0, 0, 0.16);
  overflow: auto;
}

.card-select .card-side {
  width: 100px;
  height: 60px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.card-select .title {
  font-size: 11px;
  margin-bottom: 2px;
  font-weight: 600;
}

.card-select {
  padding: 5px 5px 2px 5px;
  cursor: pointer;
}

.selected {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 3px 0.5px inset,
    rgba(0, 0, 0, 0.2) 0px -1px 2px 0px inset;
  color: #136cf8;
}

.card-display-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-display {
  width: 380px;
  height: 250px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.card-outline {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='53' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  /* border: 1px dashed #00000078; */
  padding: 10px 20px;
  margin: 20px;
}

.column-3 .navigator {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.column-3 .navigator .nav-item {
  color: #909497;
  font-weight: 500;
  padding: 2px 10px;
  font-size: 14px;
  cursor: pointer;
}

.column-3 .navigator .nav-item.active {
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.column-3 .text-column {
  display: flex;
  flex-direction: column;
}

.column-3 .text-input {
  padding: 5px 5px;
  margin: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.column-3 .print-option {
  padding: 5px;
  margin: 5px 10px;
}

.column-3 .upload-image {
  padding: 5px;
  margin: 10px 25px;
}

.column-3 .upload-image input {
  display: none;
}

.column-3 .image-input label {
  color: #797878;
  cursor: pointer;
  width: 100%;
  padding: 6px 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid #aeb6bf;
  display: flex;
  gap: 15px;
  align-items: center;
}

.column-3 .image-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
/* .column-3 .upload-image input {
  width: 100%;
  margin: 10px;
  font-size: 12px;
  border-radius: 20px;
  border: 1px solid #aeb6bf;
} */
.column-3 .print-option-button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  margin: 10px;
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  cursor: pointer;
}

.column-3 .print-option-button * {
  cursor: pointer;
}

.column-3 .print-option-button:nth-child(1) label {
  font-weight: 500;
  color: #fff;
}

.column-3 .print-option-button:nth-child(2) label {
  font-weight: 500;
  color: #ffd700;
}

.column-3 .print-option-button:nth-child(3) label {
  font-weight: 500;
  color: #b9d9eb;
}

.column-3 .text-input .input-container {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.column-3 .text-input .input-container label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.column-3 .text-input .input-container input,
.column-3 .text-input .input-container select {
  font-size: 12px;
  border-radius: 20px;
  border: 1px solid #909497;
  padding: 6px;
  font-weight: 600;
  margin-bottom: 5px;
  width: 100%;
}

.column-3 .text-input .input-container input {
  padding: 6px 15px;
}

.column-3 .text-input .input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
}

.column-3 .add-text {
  display: flex;
  font-size: 12px;
  justify-content: center;
  gap: 15px;
  font-weight: 600;
  align-items: center;
  color: #000;
  cursor: pointer;
  margin-bottom: 10px;
}

.column-3 .add-text:hover {
  color: #636262;
}

.column-3 .text-input .input-container .color-picker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 30px;
  height: 30px;
  align-self: center;
  outline: none;
  padding: 0;
  margin: 0;
}

.column-3 .text-input .input-container .color-picker::-webkit-color-swatch {
  border-radius: 50%;
}

.column-3 .text-input .input-container .color-picker::-moz-color-swatch {
  border-radius: 50%;
}

.column-3 .text-input .input-container .color-picker:focus {
  outline: none;
}

.blog-description img {
  width: 100%;
}

.column-3 .display-images {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-template-rows: repeat((2, 2fr));
  gap: 15px;
}

.column-3 .display-images .img-container {
  border-radius: 15px;
  border: 1px solid red;
  aspect-ratio: 1/1;
  border: 1px dashed #00000078;
  width: 100%;
}

.column-3 .print-option,
.column-3 .upload-image {
  max-width: 300px;
  margin: auto;
}
.img-container label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 4vw;
  font-weight: 100;
  color: #00000078;
  cursor: pointer;
}

.draggable-image:hover {
  border-left-width: 3px; /* Increase the width of the left border */
  border-right-width: 3px; /* Increase the width of the right border */
  border-style: dashed;
  border-color:#4666ff
}

.draggable-image .delete-icon {
  position: absolute;
  left: -17px;
  visibility: hidden;
}

.draggable-image:hover .delete-icon,
.draggable-image:active .delete-icon,
.column-3 .img-container:hover .delete-icon {
  visibility: visible;
}

.column-3 .img-container {
  position: relative;
}

.column-3 .delete-icon {
  position: absolute;
  right: 7px;
  cursor: pointer;
  top: 5px;
  visibility: hidden;
}

@media (max-width: 450px) {
  .card-display {
    width: 80vw;
    height: auto;
    aspect-ratio: 16/10;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 560px) {
  .column-3 .text {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 40px;
  }
}

@media (min-width: 560px) and (max-width: 920px) {
  .column-3 .text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 40px;
  }
}

@media (max-width: 920px) {
  .card-side {
    display: none;
  }

  .column-1 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }
}

@media (min-width: 920px) {
  .modal-grid {
    display: grid;
    grid-template-columns: 120px 6fr 3fr;
    height: 60vh;
    /* box-shadow: rgba(106, 105, 101, 0.48) 3px 0px 16px 0px inset; */
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.22),
      0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  }
}

@media (min-width: 1080px) {
  .modal-grid {
    grid-template-columns: 120px 6fr 3fr;
  }
}

.button-outline,
.button-primary {
  border-radius: 20px;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #4666ff;
}

.button-primary {
  background-color: #4666ff;
  color: white;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.button-outline {
  color: #4666ff;
}
