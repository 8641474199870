h4 {
  margin-top: 1.5rem;
  font-size: 1.3rem;
  font-weight: 700;
}

h5 {
  font-size: 1rem;
  font-weight: 600;
}

p,
li {
  font-size: 0.875rem;
  /* font-size: 1.99rem; */
}
