.img-container img {
  width: 90%;
  height: auto;
  border-radius: 5%;
}

.checkbox-label {
  margin-left: 10px;
  margin-top: -3px;
}
.checkbox-large {
  transform: scale(1.5); /* Adjust the scale factor as needed */
}
.star-rating {
  color: gold;
  font-size: 24px; /* Adjust the size as needed */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.scrollable-images::-webkit-scrollbar {
  display: none;
}
