@media screen and (min-width: 320px) and (max-width: 658px) {
  #nines {
    margin-top: 410px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  #nines {
    margin-top: 510px !important;
  }
}
@media screen and (min-width: 540px) and (max-width: 720px) {
  #nines {
    margin-top: 490px !important;
  }
}
@media screen and (min-width: 280px) and (max-width: 653px) {
  #nines {
    margin-top: 490px !important;
  }
}
@media screen and (min-width: 712px) and (max-width: 1138px) {
  #nines {
    margin-top: 520px !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  #nines {
    margin-top: 480px !important;
  }
}
@media screen and (min-width: 800px) and (max-width: 1280px) {
  #nines {
    margin-top: 220px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #nines {
    margin-top: 220px !important;
  }
}
/* // Hero Banner */
.hero-banner {
  background: url('../assets/images/wavebg.jpg') no-repeat 110% bottom;
  display: flex;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.hero-banner .wrap {
  padding: 35px 16px !important;
  background: url('../assets/images/yellow-c1.png') no-repeat 72% 32% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media(max-width:767px) {
  .hero-banner .wrap {
    font-size: 2.4rem;
    max-width: 100%;
    background: url('../assets/images/yellow-c1.png') no-repeat 130% 22% !important;
  }
}

.hero-banner .wrap p {
  font-size: 1.2rem;
  padding-top: 1.2rem;
  color: #000;
}

@media(max-width:767px) {
  .hero-banner .wrap p {
    font-size: 1.2rem;
  }
}

.hero-banner .wrap h1 {
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0.5rem;
  max-width: 80%;
}

@media(max-width:767px) {
  .hero-banner .wrap h1 {
    font-size: 2.4rem;
    max-width: 100%;
  }
}

.hero-banner .wrap .tagline {
  font-size: 1.5rem;
}

.hero-banner .wrap .unlock-text {
  font-size: 0.750rem;
  display: flex;
  margin-top: 0.5rem;
}
.wrap-img {
  position: absolute;
  left: 895px;
  top: 100px;
  z-index: -1;
}
.header-group-img {
  padding: 20px;
}
/* // header ends

// section2 starts */
.feature-list h2 {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.feature-list .icon-bullets {
  list-style-type: none;
  padding-left: 15px;
}

.feature-list .icon-bullets li {
  margin: 1rem 0 2.5rem 0;
}

.feature-list .icon-bullets li .icon {
  max-width: 50px;
  font-size: 40px;
  line-height: 1;
  margin-right: 1rem;
}

.feature-list p {
  color: #7c7c7c;
  font-size: 0.800rem;
}

.feature-list h6 {
  font-weight: 600;
}

.feature-list .download-app a {
  margin: 10px 10px 10px 0px;
  display: inline-block;
}
.info-text h3 {
  line-height: 1.8;
}
/* // section2 ends

// logos part starts         */
.client-logos h2 {
  font-weight: 700;
}

.client-logos .logo-list {
  margin: 1.5rem auto;
}

.client-logos .logo-list img {
  max-width: 200px;
  margin: 0 auto;
  padding: 20px 0;
}
/* // logospart ends
// flash sale starts   */
.bstselling {
  padding: 80px 0 40px;
}

.bstselling h3 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #121212;
}

.bstselling-cards {
  padding: 80px 0 40px;
}

.bstselling-cards h3 {
  font-weight: 700;
}

.bstselling-cards .flash-sale {
  padding: 25px;
  background: #d6e6ff;
  border-radius: 8px;
}

.bstselling-cards .flash-sale img {
  padding-top: 30px;
}

.bstselling-cards .flash-sale .heading {
  font-size: 20px;
  line-height: 32px;
  color: #161D25;
  padding-top: 15px;
}

.bstselling-cards .flash-sale p {
  font-size: 12px;
  line-height: 18px;
  color: #121212;
  padding: 10px 0px 0 0;
}

.bstselling-cards .flash-sale .time {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #161D25;
  padding-top: 10px;
}

.bstselling-cards .flash-sale .time-badge {
  padding-top: 10px;
}

.bstselling-cards .flash-sale .time-badge .badge {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  background: rgba(4, 69, 157, 1);
  border-radius: 8px;
  padding: 7px 10px;
}

.bstselling-cards .flash-btn {
  padding-top: 10px;
  margin: 0 auto;
}

.bstselling-cards .flash-btn .btn-primary {
  width: 100%;
  text-transform: capitalize;
}

.bstselling-cards .card-type {
  padding: 20px 15px;
  height: auto;
  overflow: auto;
  box-shadow: 0px 24px 24px rgba(31, 47, 70, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(31, 47, 70, 0.02);
}

.bstselling-cards .card-type button {
  width: 70%;
}

.bstselling-cards .card-type img .holder {
  margin: 0 auto;
}

.bstselling-cards .card-type .crdname {
  display: block;
  float: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #121212;
  padding-top: 16px;
}

.bstselling-cards .card-type .card-dsc {
  display: block;
  float: right;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #01459D;
  background: #e3eaf4;
  border-radius: 4px;
  padding: 3px 8px;
  margin-top: 13px;
}

.bstselling-cards .card-type .c-btn .btn-primary {
  background: #e5e5e6;
  border-color: #e5e5e6;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #121212;
  padding: 10px 53px;
  margin: 15px 1px;
  float: left;
}

.bstselling-cards .card-type .c-btn .btn-primary img {
  padding-left: 16px;
  float: right;
  padding-top: 15px;
}

.bstselling-cards .card-type .card-color {
  display: block;
  float: left;
  padding-top: 15px;
}

.bstselling-cards .card-cat .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  background: #E1E1E1;
  border-radius: 8px;
  margin: -15px 15px 0 15px;
}

.bstselling-cards .card-cat .progress-bar {
  background-color: #FAAD3D;
  transition: width 0.6s ease;
}

.bstselling-cards .card-cat label,
.bstselling-cards .card-cat span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #605F5F;
  margin-top: 20px;
}

.bstselling-cards .card-cat label {
  float: left;
  padding-top: 5px;
}

.bstselling-cards .card-cat span {
  float: right;
  padding-top: 5px;
}

.bstselling-cards .card-cat span.sub-text {
  float: none;
}
/* // flash sale ends
// profile details section starts */

.profile-details h2 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.profile-details h5 {
  font-weight: 500;
}

.profile-details h6 {
  font-weight: 500;
}

.profile-details ul li {
  font-size: 0.875rem;
}
/* 
// profile details section ends
// info-section starts   */
.info-section .split-text {
  font-size: 3rem;
}

.info-section .split-text .btn-group {
  padding: 0 100px;
}

.info-section .split-text .text-left h6 {
  font-size: 18px;
  font-weight: 700;
  margin-left: 15%;
  margin-top: 15px;
}

.info-section .split-text span {
  color: #868383;
}
/* // info-section ends
//footer card section starts   */
.promo-section {
  margin-top: 1rem;
}

.promo-section .promo {
  display: flex;
  font-size: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  background: #e5e5e7;
  border-radius: 8px;
  padding: 6px 8px;
  margin-bottom: 10px;
}

.promo-section .promo .badge {
  padding: 5px 12px;
  background: #121212;
  border-radius: 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-right: 10px;
}
/* //footer card section ends
//contact paragh starts   */
.connect-paragh {
  margin-top: 80px;
}

.connect-paragh h1 {
  font-weight: 800;
}

.connect-paragh p {
  font-size: 18px;
  font-weight: 500;
  color: #868383;
}
/* // contact paragh ends
// document section4 starts   */
.document-sec-4 h2 {
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .document-sec-4 h2 {
    margin-top: 30px;
  }
}

.document-sec-4 h4 {
  margin-top: 20px;
  margin-bottom: 1rem;
}

.document-sec-4 ul {
  margin-bottom: 25px;
}

.document-sec-4 ul li {
  line-height: 2rem;
  margin-bottom: 1rem;
}

.document-sec-4 h6 {
  line-height: 1.7rem;
}
/* //document section4 ends
//qr-section starts   */
.qr-section {
  padding: 2rem 0;
}

.qr-section .circle-bullet li {
  margin: 0.5rem 0 0.8rem 0;
}

.qr-section h4 {
  font-weight: 700;
}

.qr-section h6 {
  font-weight: 700;
  margin-top: 20px;
}

.qr-section li {
  line-height: 2rem;
}
/* //qr-section ends
//last-section starts   */
.last-section {
  margin-top: 100px;
}

.last-section h1 {
  margin-top: 40px;
  font-weight: 800;
  line-height: 1.5;
}

.last-section h3 {
  font-weight: 400;
  color: #868383;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 1.5rem;
}

.last-section button {
  margin-bottom: 70px;
}
/* //last-section ends
//slider-section1 starts   */
.user-types {
  position: relative;
}

.user-types::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 78%;
  bottom: 0;
  left: 0;
  background-color: rgba(20, 20, 20, 1);
  z-index: -1;
}

.user-types .left-img {
  margin-top: 30px;
}

.user-types .first-img {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-top: 100px;
}

.user-types .slider-section1 h6 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 28px;
  font-weight: 600;
}

.user-types .slider-section1 .img-block {
  overflow: hidden;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.user-types .slider-section1 .img-block:hover img {
  transform: scale(1.2);
}

.user-types .slider-section1 .img-block img {
  transition: all 0.4s ease;
  width: 100%;
}

.user-types .slider-section1 .img-block::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.9));
}

.user-types .slider-section1 .img-block .img-caption {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 20px;
  z-index: 2;
  font-size: 16px;
  font-weight: 500;
}
/* //slider-section1 ends
//slider-section2 starts   */
.slider-section2 {
  color: white;
}

.slider-section2 h2 {
  color: white;
  font-weight: 700;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.slider-section2 .text-len {
  max-width: 350px;
}

.slider-section2 .bottom-part {
  margin-top: 40px;
}
/* //slider-section2 ends

// media queries   */
@media (max-width: 767px) {
  .shadow-img {
    max-height: 350px;
  }

  .user-types::before {
    height: 88%;
  }
}

@media (max-width: 991px) {
  .block {
    display: block !important;
  }
}

@media (max-width: 1200px) {
  .btn-group {
    padding: 0 0px !important;
  }
}
.bounce {
  animation-name: bounce;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}
.blue-mob {
  box-shadow: 0px 0px 0px 10px #04459d;
  border-radius: 20px;
}
.counter {
  margin: 0 auto;
}

.counter .timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter .timer .outer {
  width: 45px;
  padding: 3px 3px;
  margin: 0 3px;
  text-align: center;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 12px;
}

.counter .timer .outer #hours,
.counter .timer .outer #days,
.counter .timer .outer #minutes,
.counter .timer .outer #seconds {
  font-size: 0.875rem;
  line-height: 3;
  width: 40px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  background: #04459d;
  border-radius: 8px;
  margin: 0 auto 5px auto;
}
.card-type {
  padding: 20px 15px;
  height: auto;
  overflow: auto;
  box-shadow: 0px 24px 24px rgba(31, 47, 70, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(31, 47, 70, 0.02);
  margin-bottom: 20px;
  min-height: 360px;

  .crt-new {
    background: #fff;
    border-radius: 12px;
    padding: 10px 15px 20px;
    position: relative;

    .badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3px 8px;
      position: absolute;
      right: 15px;
      top: 5px;
      background: #F9D572;
      border-radius: 6px;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      color: #121212;
    }

    img {
      max-width: 150px;
    }
  }
}
.price {
  font-size: 12px;
  text-align: left;
}

.card-name {
  font-size: 14px;
}

.contact-gif {
  overflow-x: hidden;

  img {
    margin-left: 15px !important;
  }
}

#ctza a.btn {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid lightgrey;
  background-color: white;
  color: rgba(4, 69, 157, 1);
  text-decoration: none;
  transition: background-color 0.3s, border 0.3s; /* Adding transition for smooth effect */
}

#ctza a.btn:hover {
  background-image: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
  border: 2px solid transparent; /* Removing border color */
  background-clip: padding-box, border-box; /* Apply background to padding and border areas */
  color: white; /* Changing text color */
}


/* Add this CSS to your existing styles or create a new CSS file */

.step {
display: flex;
align-items: flex-start;
margin-bottom: 20px;
}

.icon {
display: flex;
align-items: center;
justify-content: center;
width: 40px;
height: 40px;
border-radius: 50%;
margin-right: 10px;
}

.step-content {
flex: 1;
}

.hand-shake{
animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
animation-duration: 0.8s;        /* Change to speed up or slow down */
animation-iteration-count: infinite;  /* Never stop waving :) */
transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}
@media (min-width: 768px) and (max-width: 991.98px){
  .image-containerr {
   margin-top: 160px !important;
  }
  .gifimage{
      height: 340px !important;
      width: 350px !important;
      margin-left: 10px !important;
  }
}
.product-card-content {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 29cm;
  border: 1px solid grey;
  padding: 1cm;
  border-radius: 20px;
  max-height: 260px;
}
.btn-primary {
  background: linear-gradient(90deg, #046bd2 1.87%, #6fb4ff 100%);
  border-radius: 20px;
  width: 130px;
  margin-bottom: 10px;
}
.product-image {
  flex: 1;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}

.product-details {
  flex: 2;
  margin-left: 1cm;
}
@media (max-width: 768px) {
  .product-card-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center;
    max-width: 80%; /* Full width on smaller screens */
    padding: 0.5cm; /* Reduce padding */
    border-radius: 10px; /* Smaller border radius */
    max-height: none; /* Remove max height */
  }

  .product-image {
    flex: none; /* Reset flex to allow image to take its natural width */
    margin-bottom: 1rem; /* Add some spacing between image and details */
    /* max-width: 100%; */
  }

  .product-details {
    margin-left: 0; /* Reset margin */
    text-align: left; /* Center align text */
  }

  .product-details .card-title {
    font-size: 1.2rem; /* Adjust title font size */
    margin-bottom: 0.5rem; /* Add spacing below title */
    white-space: nowrap;
  }

  .product-details .card-text {
    font-size: 0.9rem; /* Adjust text font size */
  }

  /* Style adjustments for the "Most Popular" span tag */
  .mostpop {
    white-space: nowrap;
  }

  .card-img {
    max-width: 100% !important;
  }

  .see-all-button {
    margin-right: 100px !important;
  }

  .d-flex {
    margin-left: 14px !important;
  }
}

@media (max-width: 320px) {
  .product-card-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center;
    max-width: 80%; /* Full width on smaller screens */
    width: 320px;
    padding: 0.5cm; /* Reduce padding */
    border-radius: 10px; /* Smaller border radius */
    max-height: none; /* Remove max height */
  }

  .col-md-12 {
    width: 330px;
  }

  .see-all-button {
    margin-right: 44px !important;
  }

  .d-flex {
    margin-left: 14px !important;
  }

  .product-details {
    margin-left: 0cm !important;
  }

  .mostpop {
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 8px;
    margin-left: 0.4em !important;
  }
}
.image-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.image-container a {
  text-decoration: none;
}

.img-fluid {
  display: block;
  max-width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
}

@media only screen and (max-width: 748px) {

  .product-image{
    margin-left: 130px !important;
  }
  .mostpop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .product-image{
    margin-left: 19px !important;
  }
 
  .mostpop {
    display: none;
  }
}
.home-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Change this line */
  padding: 20px;
}

.see-all-buttony {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(4, 69, 157, 1);
  border: 2px solid grey;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.see-all-buttony:hover {
  background-image: linear-gradient(90deg, #04459D 1.87%, #3093FF 100%);
  border: 2px solid transparent; /* Removing border color */
  background-clip: padding-box, border-box; /* Apply background to padding and border areas */
  color: white; /* Changing text color */
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-img {
    max-height: 570px; /* Adjust this value as needed */
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-img {
    max-height: 650px; /* Adjust this value as needed */
  }
}

@media (min-width: 1200px) {
  .custom-img {
    max-height: 500px; /* Adjust this value as needed */
  }
}

@media (max-width: 320px) {
  .gifimagee {
    width: 100% !important;
  }
}
.text-icon-container {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Add some spacing between icon-text pairs */
}

.text-icon-container p {
  margin-left: 10px; /* Add some spacing between icon and text */
}
h5{
  margin-top: 0px !important;
}
@media screen and (min-width:800px) and (max-width: 1280px) {
  #dianc{
    margin-top: 40px !important;
  }
}
@media screen and (min-width:600px) and (max-width: 1024px) {
  #dianc{
    margin-top: 40px !important;
  }
}
@media screen and (min-width: 250px) and (max-width: 658px) {
  #mainc{
    margin-top: 100px !important;
     }
#serac{
  width: 40%;
  margin-left: 70px;
  margin-top: 130px;
}
}

@media screen and (min-width: 480px) and (max-width: 854px) {
  #mainc{
    margin-top: 170px !important;
     }
     #serac{
      width: 40%;
      margin-top: 220px;
      
    }
}

@media screen and (min-width: 712px) and (max-width: 1138px) {
  #serac{
    margin-top: 220px !important;
     }

}
@media screen and (min-width: 800px) and (max-width: 1280px) {
  #serac{
    margin-top: 20px !important;
     }

}
@media screen and (min-width: 600px) and (max-width: 960px) {
  #mainc{
    margin-top: 170px !important;
     }
     #serac{
      width: 40%;
      margin-top: 200px !important;
      
    }
}
@media screen and (min-width: 363px) and (max-width: 786px) {
  #serac{
    margin-top: 220px !important;
     }

}
@media screen and (min-width: 360px) and (max-width: 740px) {
  #serac{
    margin-top: 220px !important;
     }

}
@media screen and (min-width: 280px) and (max-width: 653px) {
  #serac{
    margin-top: 220px !important;
     }

}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #serac{
    margin-top: -20px !important;
     }

}
.img-block {
  position: relative;
}

.image-containser {
  position: relative;
  overflow: hidden; /* Add this property to hide any content that overflows the container */
}

.img-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 10px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}
@media (max-width: 880px) {
  .img-caption {
    text-align: left; /* Center the text */
    padding: 5px; /* Adjust padding as needed */

    /* Set a maximum width for the caption to ensure it wraps to two lines */
    max-width: 70%; /* Adjust as needed */
  }
  #curve {
    margin-top: -150px;
  }
  #secu {
    margin-top: 30px !important;
    align-items: center !important;
  }
}

#secu {
  margin-top: -80px;
}

.zoom-on-hover {
  transition: transform 0.4s;
}

.zoom-on-hover:hover {
  transform: scale(1.2); /* You can adjust the scale factor as needed */
}