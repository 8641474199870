/* Blog1.css */
.zoom-effect {
  transition: transform 0.3s ease-in-out;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Zoom in effect on hover */
}
.blog-container {
  margin: 40px;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns per row */
  gap: 30px; /* Adjust the gap between blog items */
  margin: 30px 0;
}

.blog-description img {
  width: 100%;
}
.blog-item {
  /* display: flex;
  flex-direction: column; */
  padding: 10px;
  box-shadow: 0 0 35px #86868685;
}

.blog-item img {
  min-height: 200px;
  max-height: 200px;
}

h5 {
  margin-top: 20px;
  color: #000;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #333333;
  font-size: 15px;
}

.category-tag {
  padding-top: 8px;
  font-weight: 500;
  font-size: 15px;
}

.read-more {
  color: #5b6efd;
  font-weight: 400;
}

.date {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  color: #000;
}

.latest-blogs {
  display: flex;
  align-items: stretch;
}

.latest-blogs-first {
  flex: 0 0 40%;
  padding: 10px;
  margin-right: 25px;
  margin-top: 25px;
}

.latest-blog-item {
  display: flex;
  /* align-self: stretch; */
  margin-top: 25px;
}

.latest-blog-item-data {
  margin-left: 10px;
}

.latest-blogs img {
  object-fit: cover;
}

.detail-image {
  max-height: 800px;
}

.icons {
  height: fit-content;
  width: fit-content;
  display: flex;
  color: #fff;
  margin: 10px 0;
}

.icon-btn {
  padding: 0 8px;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  border: 0;
}

.blog-description {
  margin: 0 10%;
  /* font-family: "Playfair Display", serif !important; */
  font-family: 'Poppins', sans-serif !important;
}

@media (max-width: 767px) {
  .blog-description {
    margin: 0;
  }

  .blog-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Three columns per row */
    /* gap: 30px; */
    margin: 30px 0;
  }

  .blog-item img {
    min-height: 150px;
    max-height: 150px;
  }

  .latest-blogs {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  .latest-blogs-first {
    margin-right: 0;
  }

  .latest-blog-item {
    display: flex;
    flex-direction: column;
  }
}
.img-fluid {
  overflow: hidden;
  width: 100%;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

/* For larger screens (desktops) */
@media (min-width: 992px) {
  .img-fluid {
    max-width: 100%; /* Make images responsive by setting max-width to 100% */
    height: auto; /* Automatically adjust image height */
  }
}

/* For medium-sized screens (tablets) */
@media (max-width: 991px) {
  .img-fluid {
    max-width: 85%; /* Make images responsive by setting max-width to 100% */
    height: auto; /* Automatically adjust image height */
  }
}

/* For small screens (mobile devices) */
@media (max-width: 767px) {
  .img-fluid {
    max-width: 101%; /* Make images responsive by setting max-width to 100% */
    height: auto; /* Automatically adjust image height */
  }
}
/* Blogs.css */

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in-out 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.blog-item {
  opacity: 0;
  transform: scale(0.8) translateY(-50px); /* Initial bounce-out and upward position */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Smoother transitions */
}

.blog-item.visible {
  opacity: 1;
  transform: scale(1) translateY(0); /* Final bounce-in and normal position */
}


/* .blog-item {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.blog-item.visible {
  opacity: 1;
  transform: translateY(0);
} */
