.scan-block {
    margin-top: 20px;
    background: url("../assets/images/QR-Code.png");
    background-size: 290px;
    background-position: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scan-block img {
    width: 130px;
  }
  