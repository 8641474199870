@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Roboto:ital@1&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
}

/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@500&display=swap");

body {
  font-family: 'Playfair Display', serif !important;
  font-size: 15px;
  color: black;
} */

/* 

@font-face {
  font-family: "NB International Regular Webfont";
  src: url("https://db.onlinewebfonts.com/t/23255fa3ff7a89589800cea3092f154f.eot");
  src: url("https://db.onlinewebfonts.com/t/23255fa3ff7a89589800cea3092f154f.eot?#iefix") format("embedded-opentype"),
       url("https://db.onlinewebfonts.com/t/23255fa3ff7a89589800cea3092f154f.woff2") format("woff2"),
       url("https://db.onlinewebfonts.com/t/23255fa3ff7a89589800cea3092f154f.woff") format("woff"),
       url("https://db.onlinewebfonts.com/t/23255fa3ff7a89589800cea3092f154f.ttf") format("truetype"),
       url("https://db.onlinewebfonts.com/t/23255fa3ff7a89589800cea3092f154f.svg#NB International Regular Webfont") format("svg");
}@import url('https://db.onlinewebfonts.com/c/23255fa3ff7a89589800cea3092f154f?family=NB+International+Regular+Webfont'); */
